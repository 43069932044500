<template>
  <div class="estate">
    <div class="suggest">
      <property-title
        :background-title="enPageTitle"
        :title="pageName"
        :suggest="suggest"
      ></property-title>
    </div>
    <div class="main-content">
      <div class="info-cover">
        <company-info-item
          v-for="(info, idx) in infos"
          :key="`${idx}`"
          :num="info.num"
          :dw="info.dw"
          :name="info.name"
          :has-icon="idx > 0"
        ></company-info-item>
      </div>
      <div class="type-cover">
        <div class="left">
          <div class="name">{{ typeInfo.name }}</div>
          <div class="en-name">{{ typeInfo.enName }}</div>
          <div class="type-suggest">{{ typeInfo.suggest }}</div>
        </div>
        <div class="right">
          <img src="../../assets/imgs/estate-type.png" alt="" />
        </div>
        <div class="rect">
          <div
            class="rect-item"
            v-for="(item, idx) in typeList"
            :key="`${idx}`"
          >
            <div class="rect-left">
              <img src="../../assets/imgs/system-8.png" alt="" class="point" />
              <div class="line"></div>
            </div>
            <div class="rect-right">
              <div class="rect-name">{{ item.name }}</div>
              <div class="rect-suggest">{{ item.suggest }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="system">
        <property-title
          :backgroundTitle="backgroundTitle"
          :title="systemInfo.name"
          :suggest="systemInfo.suggest"
        ></property-title>
        <div class="list-cover">
          <div
            class="list-item"
            v-for="(item, idx) in systemList"
            :key="`${idx}`"
            @mouseenter="mouseEnterIndex = idx + 1"
            @mouseleave="mouseEnterIndex = null"
          >
            <img :src="item.img" alt="" />
            <span>{{ item.name }}</span>
            <img
              v-show="mouseEnterIndex === idx + 1"
              src="../../assets/imgs/system-7.png"
              alt=""
              class="triangle"
            />
          </div>
          <div class="my-tooltip" v-show="mouseEnterIndex">
            {{ mouseEnterIndex ? systemList[mouseEnterIndex - 1].suggest : "" }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  ESTATE_SUGGEST,
  ESTATE_INFO_LIST,
  ESTATE_SYSTEM_INFO,
  ESTATE_SYSTEM_LIST,
  ESTATE_TYPE_INFO,
  ESTATE_TYPE_LIST,
} from "./config";
import PropertyTitle from "@/components/common/PropertyTitle.vue";
import CompanyInfoItem from "../Company/components/CompanyInfoItem.vue";
export default {
  name: "estate",
  components: {
    PropertyTitle,
    CompanyInfoItem,
  },
  metaInfo: {
    title: '爱情地产',
    meta: [{
      name: 'keyWords',
      content: '爱情地产'
    },
    {
      name: 'description',
      content: ''
    }]
  },
  data() {
    return {
      backgroundTitle: "SERVICE",
      pageName: "",
      enPageTitle: "",
      suggest: ESTATE_SUGGEST,
      infos: ESTATE_INFO_LIST,
      systemInfo: ESTATE_SYSTEM_INFO,
      systemList: ESTATE_SYSTEM_LIST,
      typeInfo: ESTATE_TYPE_INFO,
      typeList: ESTATE_TYPE_LIST,
      mouseEnterIndex: null,
    };
  },
  created() {
    this.pageName = this.$route.meta.name;
    this.enPageTitle = this.$route.meta.enTitle;
  },
};
</script>

<style lang="less" scoped>
.estate {
  padding-bottom: 215px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .info-cover {
    margin-top: 67px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 0 400px;
  }
  .system {
    margin-top: 86px;
    position: relative;
    width: 1920px;
  }
  .list-cover {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 0 150px;
    margin-top: 80px;
    position: relative;
    .list-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      img {
        width: 86px;
        height: 86px;
      }
      span {
        margin-top: 14px;
        font-size: 18px;
        line-height: 25px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
      }
      .triangle {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 142px;
        width: 54px;
        height: 20px;
      }
    }
    .my-tooltip {
      position: absolute;
      left: 210px;
      right: 210px;
      top: 160px;
      height: 120px;
      display: flex;
      align-items: center;
      padding: 0 44px;
      font-size: 22px;
      line-height: 30px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      background: #f8f8f8;
      transition: 0.3s all;
    }
  }
  .type-cover {
    width: 1920px;
    margin-top: 67px;
    background: url('../../assets/imgs/bgLine.png') no-repeat top left;
    height: 693px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 160px;
    position: relative;
    .left {
      .name {
        font-size: 35px;
        line-height: 49px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #000000;
      }
      .en-name {
        font-size: 20px;
        line-height: 28px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #cccccc;
      }
      .type-suggest {
        margin-top: 26px;
        font-size: 22px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999;
        line-height: 40px;
        width: 388px;
      }
    }
    .right {
      img {
        width: 846px;
        height: 555px;
      }
    }
    .rect {
      position: absolute;
      background: #4d4948;
      border-radius: 6px;
      width: 445px;
      height: 346px;
      padding: 45px 0 0 45px;
      left: 50%;
      transform: translateX(-50%);
      bottom: 50px;
      &:first-child {
        margin-top: 0;
      }
      .rect-item {
        display: flex;
        margin-top: 15px;
        &:first-child {
          margin-top: 0;
        }
        .rect-left {
          position: relative;
          .point {
            width: 14px;
          }
          .line {
            position: absolute;
            left: 5px;
            top: 20px;
            bottom: -15px;
            border: 0.5px dashed #999999;
          }
        }
        .rect-right {
          padding-left: 20px;
        }
        .rect-name {
          font-size: 20px;
          line-height: 28px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #ffffff;
        }
        .rect-suggest {
          margin-top: 7px;
          font-size: 16px;
          line-height: 22px;
          letter-spacing: 1px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
        }
      }
    }
  }
}
</style>
