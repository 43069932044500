<template>
  <div class="company-about">
    <div class="suggest">
      <property-title :background-title="enPageTitle" :title="pageName" :suggest="suggestText"></property-title>
    </div>
    <div class="company-info">
      <div class="info-item" v-for="(item, idx) in infoList" :key="`${idx}`">
        <company-info-item
          :num="item.num"
          :dw="item.dw"
          :name="item.name"
          :has-icon="idx > 0"
        ></company-info-item>
      </div>
    </div>
    <div class="company-tip">*数据截止2023年9月</div>
    <div class="main-content">
      <div class="left">
        <img src="../../assets/imgs/company-text.png" alt="" class="bg-text" />
        <div class="content-text">
          <div class="company-name">{{ companyName }}</div>
          <div
            class="suggest-item"
            v-for="(item, idx) in suggests.slice(1)"
            :key="`${idx}`"
          >
            {{ item }}
          </div>
        </div>
      </div>
      <div class="right">
        <img
          src="../../assets/imgs/company-about.png"
          alt=""
          class="company-about-img"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  COMPANY_INFO_LIST,
  COMPANY_NAME,
  COMPANY_SUGGEST_LIST,
  COMPANY_SUGGEST
} from "./config";
import PropertyTitle from '@/components/common/PropertyTitle.vue';
import CompanyInfoItem from "./components/CompanyInfoItem.vue";
export default {
  name: "about",
  components: {
    PropertyTitle,
    CompanyInfoItem,
  },
  metaInfo: {
    title: '爱情地产',
    meta: [{
      name: 'keyWords',
      content: '爱情地产'
    },
    {
      name: 'description',
      content: ''
    }]
  },
  data() {
    return {
      infoList: COMPANY_INFO_LIST,
      companyName: COMPANY_NAME,
      suggests: COMPANY_SUGGEST_LIST,
      suggestText: COMPANY_SUGGEST,
      pageName: '',
      enPageTitle: ''
    };
  },
  created() {
    this.pageName = this.$route.meta.name;
    this.enPageTitle = this.$route.meta.enTitle;
  }
};
</script>

<style lang="less" scoped>
.company-about {
  padding-bottom: 133px;
  .company-info {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 92px;
    .info-item {
      margin-left: 105px;
      &:first-child {
        margin-left: 0;
      }
    }
  }
  .company-tip {
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    text-align: right;
    margin-top: 20px;
    margin-right: 160px;
    line-height: 25px;
  }
  .main-content {
    display: flex;
    justify-content: center;
    margin-top: 91px;
    .left {
      flex-grow: 0;
      flex-shrink: 0;
      width: 1054px;
      height: 847px;
      background: #f8f8f8;
      .bg-text {
        width: 100%;
      }
      .content-text {
        margin-top: 93px;
        margin-left: 160px;
        .company-name {
          font-size: 30px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #000000;
          margin-bottom: 45px;
          line-height: 42px;
        }
        .suggest-item {
          font-size: 22px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 40px;
          width: 612px;
          margin-top: 40px;
          &:first-child {
            margin-top: 0;
          }
        }
      }
    }
    .right {
      margin-left: -114px;
      margin-top: 99px;
      .company-about-img {
        width: 980px;
        height: 607px;
      }
    }
  }
}
</style>
