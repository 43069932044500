<template>
  <div class="course">
    <div class="suggest">
      <property-title
        :background-title="enPageTitle"
        :title="pageName"
      ></property-title>
    </div>
    <div class="main-content">
      <div class="left">
        <img src="../../assets/imgs/course-1.png" alt="" class="bottom-img" />
        <div class="top-img-cover">
          <img
            alt=""
            class="top-img"
            v-for="(_, idx) in courseList"
            :key="`${idx}`"
            :src="_.src"
            :class="
              activeProgress === idx + 1
                ? 'current'
                : activeProgress > idx + 1
                ? 'prev'
                : 'next'
            "
          />
        </div>
      </div>
      <div class="right">
        <img src="../../assets/imgs/course-bg.png" alt="" class="right-bg" />
        <div class="right-content">
          <div
            class="my-progress"
            :style="{ left: (activeProgress - 1) * -410 + 'px' }"
          >
            <course-progress-item
              v-for="(item, idx) in courseList"
              :key="`${idx}`"
              :time="item.years"
              :content="item.title"
              :is-active="activeProgress === idx + 1"
              :type="idx % 2"
              :is-last="idx === courseList.length - 1"
            ></course-progress-item>
          </div>
          <div class="btn-cover">
            <div
              class="course-btn"
              :class="
                activeProgress > 1 && courseList.length > 1 ? 'active' : ''
              "
              @click="progressPrev"
            >
              上一项
            </div>
            <div
              class="course-btn"
              :class="
                activeProgress < courseList.length && courseList.length > 1
                  ? 'active'
                  : ''
              "
              @click="progressNext"
            >
              下一项
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PropertyTitle from "@/components/common/PropertyTitle.vue";
import CourseProgressItem from "./components/CourseProgressItem.vue";
import { apiGetDevelopment } from "../../api/index";
export default {
  name: "course",
  components: {
    PropertyTitle,
    CourseProgressItem,
  },
  metaInfo: {
    title: '爱情地产',
    meta: [{
      name: 'keyWords',
      content: '爱情地产'
    },
    {
      name: 'description',
      content: ''
    }]
  },
  data() {
    return {
      pageName: "",
      enPageTitle: "",
      courseList: [],
      activeProgress: 1,
    };
  },
  methods: {
    init() {
      apiGetDevelopment().then((data) => {
        if (data.data.status == 1) {
          data.data.course.forEach((item) => {
            item.src = "http://www.weaiove.com" + item.photo;
          });
          this.courseList = data.data.course;
        }
      });
    },
    progressPrev() {
      if (this.activeProgress > 1) {
        this.activeProgress--;
      }
    },
    progressNext() {
      if (this.activeProgress < this.courseList.length) {
        this.activeProgress++;
      }
    },
  },
  created() {
    this.pageName = this.$route.meta.name;
    this.enPageTitle = this.$route.meta.enTitle;
    this.init();
  },
};
</script>

<style lang="less" scoped>

.course {
  padding-bottom: 96px;
  .main-content {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    .left {
      position: relative;
      .bottom-img {
        width: 665px;
        height: 724px;
      }
      .top-img-cover {
        position: absolute;
        left: 239px;
        top: 50%;
        transform: translateY(-50%);
        z-index: 4;
        .top-img {
          position: absolute;
          width: 536px;
          height: 536px;
          top: 50%;
          transform: translateY(-50%);
          &.current {
            opacity: 1;
            z-index: 2;
            left: 0;
            transition: 0.3s all;
          }
          &.next {
            opacity: 0;
            left: 0;
            z-index: 1;
            transition: 0.3s all;
          }
          &.prev {
            opacity: 0;
            left: -100%;
            z-index: 1;
            transition: 0.3s all;
          }
        }
      }
    }
    .right {
      margin-left: 75px;
      position: relative;
      font-size: 0;
      z-index: 3;
      .right-bg {
        width: 1178px;
        height: 636px;
      }
      .right-content {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        padding-left: 65px;
        .my-progress {
          display: flex;
          align-items: center;
          height: 60%;
          position: absolute;
          left: 0;
          top: 0;
          transition: 0.3s all ease-in-out;
          z-index: 2;
        
        }
        .btn-cover {
          font-size: 22px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #ffffff;
          border-radius: 5px;
          overflow: hidden;
          display: flex;
          align-items: center;
          margin-left: 40px;
          position: absolute;
          left: 177px;
          bottom: 135px;
          .course-btn {
            width: 151px;
            height: 50px;
            background: #d8d8d8;
            display: flex;
            align-items: center;
            justify-content: center;
            &.active {
              background: #e95506;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
</style>
