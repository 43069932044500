<template>
  <div class="concept">
    <div class="suggest">
      <property-title
        :background-title="enPageTitle"
        :title="pageName"
        :suggest="suggestText"
      ></property-title>
    </div>
    <div class="main-content">
      <div
        class="card-item"
        v-for="(card, idx) in cards"
        :key="`${idx}`"
        :class="active === idx + 1 ? 'active' : active ? 'not-active' : ''"
        @mouseenter="active = idx + 1"
        @mouseleave="active = null"
      >
        <img :src="card.img" alt="" class="bg-img" />
        <div class="item-content">
          <div class="item-name">{{ card.name }}</div>
          <div class="item-en-name">{{ card.enName }}</div>
          <div class="item-tip">{{ card.suggest }}</div>
        </div>
      </div>
    </div>
    <div class="bottom-content">
      <img src="../../assets/imgs/idea-4.png" alt="" class="bg-img" />
      <div class="content">
        <div
          class="content-item"
          v-for="(item, idx) in bottomContent"
          :key="`${idx}`"
        >
          <span>{{ item.name }}</span>
          <span>{{ item.tip }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  COMPANY_IDEA,
  COMPANY_CARD_LIST,
  COMPANY_BOTTOM_CONTENT,
} from "./config";
import PropertyTitle from "@/components/common/PropertyTitle.vue";
export default {
  name: "concept",
  components: {
    PropertyTitle,
  },
  metaInfo: {
    title: '爱情地产',
    meta: [{
      name: 'keyWords',
      content: '爱情地产'
    },
    {
      name: 'description',
      content: ''
    }]
  },
  data() {
    return {
      suggestText: COMPANY_IDEA,
      cards: COMPANY_CARD_LIST,
      bottomContent: COMPANY_BOTTOM_CONTENT,
      pageName: "",
      enPageTitle: "",
      active: 0,
    };
  },
  created() {
    this.pageName = this.$route.meta.name;
    this.enPageTitle = this.$route.meta.enTitle;
  },
};
</script>

<style lang="less" scoped>
.concept {
  padding-bottom: 10px;
  overflow-x: hidden;
  .main-content {
    margin-top: 54px;
    display: flex;
    justify-content: center;
    .card-item {
      position: relative;
      font-size: 0;
      transition: 0.3s all;
      .bg-img {
        width: 533px;
        height: 666px;
        transition: 0.3s all;
      }
      .item-content {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        padding: 197px 0 0 54px;
        background: rgba(0, 0, 0, 0.3);
        cursor: pointer;
        box-sizing: border-box;
        .item-name {
          font-size: 45px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #ffffff;
          line-height: 63px;
        }
        .item-en-name {
          margin-top: 7px;
          font-size: 20px;
          line-height: 28px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          opacity: 0.5;
        }
        .item-tip {
          font-size: 35px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #ffffff;
          margin-top: 44px;
          line-height: 49px;
          letter-spacing: 2;
          opacity: 0;
          transform: translateX(600px);
        }
      }
      &.active {
        .bg-img {
          width: 731px;
        }
        transition: 0.3s all;
        .item-content {
          background: rgba(0, 0, 0, 0.1);
          transition: 0.3s all;
          .item-tip {
            opacity: 1;
            transform: translateX(0);
            transition: 0.3s all;
          }
        }
      }
      &.not-active {
        .bg-img {
          width: 434px;
        }
      }
    }
  }
  .bottom-content {
    position: relative;
    margin-top: 61px;
    font-size: 0;
    .bg-img {
      width: 100%;
      height: 566px;
    }
    .content {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 40px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      line-height: 82px;
      .content-item {
        margin-top: 10px;
        &:first-child {
          margin-top: 0;
        }
        span:last-child {
          margin-left: 40px;
          letter-spacing: 1px;
        }
      }
    }
  }
}
</style>
