<template>
  <div class="layout">
    <div class="suggest">
      <property-title
        :background-title="enPageTitle"
        :title="pageName"
      ></property-title>
    </div>
    <div class="main-content">
      <div class="left">
        <img src="../../assets/imgs/layout.png" alt="" class="left-img" />
      </div>
      <div class="right">
        <div class="title">{{ layoutText.title }}</div>
        <div class="name">{{ layoutText.name }}</div>
        <div
          class="suggest"
          :class="'suggest-' + idx"
          v-for="(suggest, idx) in layoutText.suggest"
          :key="`${idx}`"
        >
          {{ suggest }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { COMPANY_LAYOUT } from "./config";
import PropertyTitle from "@/components/common/PropertyTitle.vue";
export default {
  name: "layout",
  components: {
    PropertyTitle,
  },
  metaInfo: {
    title: '爱情地产',
    meta: [{
      name: 'keyWords',
      content: '爱情地产'
    },
    {
      name: 'description',
      content: ''
    }]
  },
  data() {
    return {
      pageName: "",
      enPageTitle: "",
      layoutText: COMPANY_LAYOUT,
    };
  },
  created() {
    this.pageName = this.$route.meta.name;
    this.enPageTitle = this.$route.meta.enTitle;
  },
};
</script>

<style lang="less" scoped>
.layout {
  padding-bottom: 127px;
  .main-content {
    display: flex;
    justify-content: center;
    .left {
      .left-img {
        width: 747px;
        height: 880px;
      }
    }
    .right {
      margin-left: 123px;
      padding-top: 106px;
      .title {
        font-size: 100px;
        font-family: '黑体';
        font-weight: bold;
        color: #efefef;
        line-height: 117px;
      }
      .name {
        font-size: 30px;
        line-height: 42px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #000000;
      }
      .suggest {
        font-size: 22px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 40px;
        width: 730px;
        &.suggest-0 {
          margin-top: 40px;
        }
      }
    }
  }
}
</style>
