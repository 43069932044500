<template>
  <div class="dwell">
    <div class="suggest">
      <property-title
        :background-title="enPageTitle"
        :title="pageName"
        :suggest="suggest"
      ></property-title>
    </div>
    <div class="main-content">
      <div class="search-cover">
        <div class="city-cover">
          <div
            class="city-item"
            :class="arrlistParams.area === city ? 'active' : ''"
            v-for="city in cityList"
            :key="city"
            @click="tabCity(city)"
          >
            {{ city }}
          </div>
        </div>
        <div class="form-cover">
          <div class="form-item">
            <div class="key">当前位置：</div>
            <div class="value">
              <select
                name=""
                id=""
                v-model="arrlistParams.city"
                @change="initParams"
              >
                <option v-for="(item, index) in location" :key="index">
                  {{ item.city }}
                </option>
              </select>
            </div>
          </div>
          <div class="form-item">
            <div class="key">销售状态：</div>
            <div class="value">
              <select
                name=""
                id=""
                v-model="arrlistParams.type"
                @change="initParams"
              >
                <option v-for="(item, index) in sellStatus" :key="index">
                  {{ item.type }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="dwell-list">
        <div class="list-content">
          <div
            class="dwell-item-cover"
            v-for="(dwell, idx) in dwellList"
            :key="`${idx}`"
            @click="detailHandler(idx)"
          >
            <dwell-item
              :city="dwell.city"
              :name="dwell.title"
              :address="dwell.address"
              :phone="dwell.tel"
              :img="dwell.picture"
            ></dwell-item>
          </div>
        </div>
      </div>
    </div>
    <div class="moban" v-show="detailVisible" v-if="dwellList[index]">
      <div class="content" ref="tk">
        <div class="img-cover">
          <div class="left-img">
            <img
              :src="'http://www.weaiove.com' + dwellList[index].photo"
              alt=""
            />
          </div>
          <div class="right-img">
            <img
              v-for="(item, index) in dwellList[index].images"
              :key="index"
              :src="'http://www.weaiove.com' + item.image"
              alt=""
            />
          </div>
        </div>
        <div class="city-name">
          {{ dwellList[index].city }} | {{ dwellList[index].title }}
        </div>
        <div class="warn-text">{{ dwellList[index].introduction }}</div>
        <div class="tip-text">
          {{ dwellList[index].description }}
        </div>
        <img
          src="../../assets/imgs/detail-6.png"
          alt=""
          class="close-btn"
          @click="detailVisible = false"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  DWELL_SUGGEST,
  DWELL_CITY_LIST,
  DWELL_POS_LIST,
  DWELL_SALE_LIST,
  DWELL_LIST,
} from "./config";
import PropertyTitle from "@/components/common/PropertyTitle.vue";
import DwellItem from "./components/DwellItem.vue";
import { apiGetArrlist } from "../../api/index";

export default {
  name: "dwell",
  components: {
    PropertyTitle,
    DwellItem,
  },
  metaInfo: {
    title: '爱情地产',
    meta: [{
      name: 'keyWords',
      content: '爱情地产'
    },
    {
      name: 'description',
      content: ''
    }]
  },
  data() {
    return {
      pageName: "",
      enPageTitle: "",
      suggest: DWELL_SUGGEST,
      cityList: DWELL_CITY_LIST,
      posList: DWELL_POS_LIST,
      saleList: DWELL_SALE_LIST,
      dwellList: [],
      activeCity: "京津冀城市群",
      detailVisible: false,
      index: 0,
      location: [],
      sellStatus: [],
      arrlistParams: {
        area: "京津冀城市群",
        city: "全部",
        type: "全部",
      },
    };
  },
  methods: {
    detailHandler(id) {
      this.detailVisible = true;
      this.index = id;
      let scale = document.body.style.transform.slice(6, -1) || 1;
      let scrollTOp = document.documentElement.scrollTop;
      this.$refs.tk.style.top = scrollTOp / scale + 100 * scale + "px";
    },
    initParams() {
      let params = 'area='+this.arrlistParams.area+"&"+'city='+this.arrlistParams.city+"&"+'type='+this.arrlistParams.type
      apiGetArrlist(params).then((data) => {
        this.cityList = data.data.areagroup.map((res) => res.group);
        this.dwellList = data.data.houses;
        this.location = data.data.citygroup;
        this.sellStatus = data.data.typegroup;
        if (!data.data.houses) this.dwellList = [];
      });
    },
    tabCity(val) {
      this.arrlistParams.area = val;
      this.arrlistParams = {
        area: val,
        city: "全部",
        type: "全部",
      };
      this.initParams();
    },
  },
  created() {
    this.pageName = this.$route.meta.name;
    this.enPageTitle = this.$route.meta.enTitle;
    this.initParams();
  },
};
</script>

<style lang="less" scoped>
.dwell {
  .moban {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.7);
    .content {
      position: relative;
      padding: 30px 49px;
      background: #ffffff;
      width: 1600px;
      margin: auto;
      margin-top: 20vh;
      .img-cover {
        display: flex;
        height: 522px;
        .left-img {
          width: 928px;
          img {
            width: 100%;
          }
        }
        .right-img {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          flex: 1;
          img {
            width: 246px;
            height: 246px;
            margin-left: 20px;
            margin-bottom: 30px;
            &:nth-child(3),
            &:nth-child(4) {
              margin-bottom: 0;
            }
          }
        }
      }
      .city-name {
        margin-top: 30px;
        line-height: 49px;
        font-size: 35px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
      }
      .warn-text {
        margin-top: 15px;
        font-size: 22px;
        line-height: 30px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #e95506;
      }
      .tip-text {
        margin-top: 20px;
        font-size: 22px;
        line-height: 30px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
      }
      .close-btn {
        position: absolute;
        right: -76px;
        top: 0;
        width: 76px;
        height: 76px;
        cursor: pointer;
      }
    }
  }
  .main-content {
    margin-top: 75px;
    .search-cover {
      background: #ffffff;
      box-shadow: 0px 4px 14px 7px rgba(0, 0, 0, 0.01);
      .city-cover {
        display: flex;
        align-items: center;
        height: 131px;
        // box-shadow: 0px 1px 0px 0px #eee;
        padding: 0 260px;
        .city-item {
          width: 25%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 30px;
          line-height: 42px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #666666;
          transition: 0.3s all;
          cursor: pointer;
          &.active {
            color: #e95506;
          }
        }
      }
      .form-cover {
        height: 142px;
        padding-bottom: 22px;
        .form-item {
          // box-shadow: 0px 1px 0px 0px #cccccc;
          // border-bottom: 1px solid #ccc;
          margin-left: 163px;
          margin-right: 84px;
          height: 100%;
          width: 475px;
        }
      }
      .form-cover{
        box-shadow: 0px 4px 14px 7px rgba(0,0,0,0.05);
      }
      .form-cover,
      .form-item {
        display: flex;
        align-items: center;
        .key {
          width: 160px;
          font-size: 28px;
          line-height: 40px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
        }
        .value select {
          border: none;
          width: 310px;
          height: 40px;
          outline: none;
          font-size: 28px;
          left: 40px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #666666;
          text-align: center;
          -webkit-appearance: none;
          background: url('../../assets/images/selectDown.png') no-repeat right;
          background-size: 20px 20px;
          background-position-x: 96%;
          option{
            font-size: 18px;
          }
        }
      }
    }
    .dwell-list {
      background: url("../../assets/imgs/bgLine.png") no-repeat top left;
      padding: 44px 0;
      .list-content {
        width: 1920px;
        padding: 0 160px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
      }
      .dwell-item-cover {
        display: flex;
        justify-content: center;
        margin-left: 38px;
        margin-bottom: 25px;
        &:nth-child(3n-2) {
          margin-left: 0;
        }
      }
    }
  }
}
</style>
