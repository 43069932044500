<template>
  <div class="develop">
    <div class="suggest">
      <property-title
        :background-title="enPageTitle"
        :title="pageName"
      ></property-title>
    </div>
    <div class="main-content">
      <div class="year-line">
        <div class="prev" @click="upPage" v-show="active != 0">
          <img src="../../assets/imgs/next-prev-icon.png" alt="" />
        </div>
        <div class="year-line-cover">
          <div
            v-for="year in showYears"
            class="year-item"
            :class="active === year.id ? 'active' : ''"
            :key="`${year.id}`"
            @click="active = year.id"
          >
           <span>{{ year.key }}</span>
            <div class="line"></div>
          </div>
        </div>
        <div class="next" @click="downPage" v-show="active +3 < years.length">
          <img src="../../assets/imgs/next-prev-icon.png" alt="" />
        </div>
      </div>
      <div class="content">
        <div
          class="content-item"
          v-for="(item, idx) in articles.value"
          :key="`${idx}`"
        >
          <div class="time">
            <div class="time-show">{{ item.dates + "." + item.month }}</div>
            <div class="point-line">
              <div class="point">
                <point :active="true"></point>
              </div>
              <div class="line"></div>
            </div>
          </div>
          <div class="article">
            <div class="article-item" v-html="item.brand"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { apiGetDevelop } from "../../api/index";
// import { DEVELOP_YEARS, DEVELOP_ARTICLE_LIST } from "./config";
import PropertyTitle from "@/components/common/PropertyTitle.vue";
import Point from "./components/Point.vue";
export default {
  name: "develop",
  components: {
    PropertyTitle,
    Point,
  },
  metaInfo: {
    title: '爱情地产',
    meta: [{
      name: 'keyWords',
      content: '爱情地产'
    },
    {
      name: 'description',
      content: ''
    }]
  },
  data() {
    return {
      pageName: "",
      enPageTitle: "",
      years: [],
      active: 0,
      list: [],
    };
  },
  computed: {
    articles() {
      return this.list.filter((item, idx) => idx == this.active)[0] || "";
    },
    showYears() {
      let currentlist = this.years.map((res, idx) => ({ id: idx, key: res }));
      if (this.active < 3) {
        return currentlist.slice(0, 4);
      } else if (this.active + 4 > currentlist.length) {
        return currentlist.slice(currentlist.length - 4);
      } else {
        return currentlist.slice(this.active - 1, this.active + 3);
      }
    },
  },
  methods: {
    init() {
      apiGetDevelop().then((data) => {
        this.years = data.data.list.map((res) => res.key);
        this.list = data.data.list;
      });
    },
     upPage() {
      if (this.active > 0) this.active--;
    },
    downPage() {
      if (this.active < this.years.length - 1) this.active++;
    },
  },
  created() {
    this.pageName = this.$route.meta.name;
    this.enPageTitle = this.$route.meta.enTitle;
    this.init();
  },
};
</script>

<style lang="less" scoped>
.develop {
  padding-bottom: 74px;
  .main-content {
    margin-top: 45px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .year-line {
      background: #f8f8f8;
      height: 100px;
      width: 1600px;
      display: flex;
      align-items: center;
      .year-line-cover {
        flex-grow: 1;
        flex-shrink: 1;
        display: flex;
        align-items: center;
        height: 100%;
        .year-item {
          width: 25%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 50px;
          line-height: 58px;
          font-family: DINAlternate-Bold, DINAlternate;
          font-weight: bold;
          color: #999;
          position: relative;
          cursor: pointer;
          transition: 0.3s all;
          .line {
            position: absolute;
            left: 50%;
            bottom: 0;
            width: 0;
            transform: translateX(-50%);
            height: 6px;
            background: #e95506;
            transition: 0.3s all;
          }
          &.active {
            color: #e95506;
            .line {
              width: 80px;
            }
          }
        }
      }
      .prev,
      .next {
        height: 100px;
        width: 146px;
        flex-grow: 0;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 20px;
          height: 20px;
        }
      }
      .prev {
        border-right: 1px solid #ccc;
      }
      .next {
        border-left: 1px solid #ccc;
      }
      .prev img {
        transform: rotate(180deg);
      }
    }
    .content {
      margin-top: 80px;
      width: 1600px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .content-item {
        display: flex;
        width: 100%;
        .time,
        .article {
          min-height: 254px;
        }
        .time {
          position: relative;
          padding: 0 67px 0 111px;
          .time-show {
            font-size: 50px;
            line-height: 58px;
            font-family: DINAlternate-Bold, DINAlternate;
            font-weight: bold;
            color: #cccccc;
          }
          .point {
            position: absolute;
            top: 6px;
            right: 0;
            z-index: 2;
          }
          .line {
            z-index: 1;
            position: absolute;
            right: 16px;
            top: 17px;
            bottom: -10px;
            background: #e9e9e9;
            width: 2px;
          }
        }
        .article {
          font-size: 22px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          left: 20px;
          margin-left: 60px;
          .article-item {
            width: 1088px;
            line-height: 40px;
          }
        }
      }
    }
  }
}
</style>
