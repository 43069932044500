// 爱情地产tabs
export const TABS = [
  "集团简介",
  "品牌理念",
  "战略布局",
  "发展历程",
  "品牌荣誉",
];

// 爱情地产-集团简介 4个小块
export const COMPANY_INFO_LIST = [
  {
    num: "2018",
    dw: "年",
    name: "成立于",
  },
  {
    num: "2000w",
    dw: "m²",
    name: "土地储备总面积",
  },
  {
    num: "30",
    dw: "",
    name: "项目总数",
  },
  {
    num: "20",
    // dw: "年",
    name: "入驻城市",
  },
];

export const COMPANY_NAME = "关于爱情地产";

// 爱情地产-集团简介 内容
export const COMPANY_SUGGEST_LIST = [
  "爱情地产成立于2018年，是一家集地产开发、商业运营及物业服务于一体的大型多元化综合性企业集团。截至2022年3月，土储总面积近2000万㎡，总货值逾千亿。",
  "公司以用户需求为基准点，聚合产品创新与服务创新，双向驱动，营造未来理想生活场景，引领未来人居生活方式，构建从新能源应用到绿色建筑及商业创新等纵向一体的多元化生态圈经济。",
];

// 集团简介内容
export const COMPANY_SUGGEST =
  "爱情地产成立于2018年，是一家集地产开发、商业运营及物业服务于一体的大型多元化综合性企业集团。截至2022年3月，土储总面积近2000万㎡，总货值逾千亿。";

// 品牌理念内容
export const COMPANY_IDEA =
  "中国正处在一个前所未见的高速城市化进程中，爱情地产通过对城市发展方向和时机的正确把握，开启对于未来、城市、建筑、社群、人居的全新思考。面向未来，成为绿色低碳智慧城市的先行者，为城市匠筑更有爱的生活，日日皆新，长久延绵。";

// 品牌理念卡片内容
export const COMPANY_CARD_LIST = [
  {
    name: "品牌愿景",
    enName: "Brand Vision",
    img: require("../../assets/imgs/idea-1.png"),
    suggest: "-低碳智慧城市运营商",
  },
  {
    name: "品牌使命",
    enName: "Brand Mission",
    img: require("../../assets/imgs/idea-2.png"),
    suggest: "-创领绿色智慧城市新生态",
  },
  {
    name: "品牌主张",
    enName: "Brand Proposition",
    img: require("../../assets/imgs/idea-3.png"),
    suggest: "-科技·匠筑·健康·生活",
  },
];

// 品牌理念-底部的内容
export const COMPANY_BOTTOM_CONTENT = [
  {
    name: "科技",
    tip: "科技引领变革，预见未来建筑",
  },
  {
    name: "匠筑",
    tip: "匠心筑就品质，塑造人居标杆",
  },
  {
    name: "健康",
    tip: "驱动生态创新，赋能健康空间",
  },
  {
    name: "生活",
    tip: "创新服务体验，重构舒适社区",
  },
];

// 战略布局文本
export const COMPANY_LAYOUT = {
  title: "SINCE2018",
  name: "布局全国 擘画时代蓝图",
  suggest: [
    "爱情地产战略布局京津冀城市群、长三角城市群、华中城市群、成渝城市群、粤港澳大湾区五大城市群的21个强二线城市，目前已进驻天津、重庆、长沙、佛山、无锡、苏州、嘉兴、泰州、青岛、保定、日照等12个省市，打造了30余个高品质项目。",
    "其他布局城市包括武汉、昆明、西安、南京、唐山、荆门、东莞、上饶等，致力于提高当地生活品质，打造健康人居新典范。",
  ],
};

// 品牌荣誉年度列表
export const DEVELOP_YEARS = ['2021', '2020', '2019', '2018'];

// 品牌荣誉内容列表
export const DEVELOP_ARTICLE_LIST = [
  {
    time: '2021.05',
    pages: [
      '12月2日，第一财经携手同策研究院联合呈现《2020中国上市房企价值榜》，入选"2020年上市房企盈利能力榜单TOP10"。',
      '12月9日，金地集团荣获由易居克而瑞与全国房地产CIO联盟联合发布的"2020年度中国地产数字力TOP20房企"奖项。'
    ]
  },
  {
    time: '2021.05',
    pages: [
      '12月2日，第一财经携手同策研究院联合呈现《2020中国上市房企价值榜》，入选"2020年上市房企盈利能力榜单TOP10"。',
      '12月9日，金地集团荣获由易居克而瑞与全国房地产CIO联盟联合发布的"2020年度中国地产数字力TOP20房企"奖项。'
    ]
  },
  {
    time: '2021.05',
    pages: [
      '12月2日，第一财经携手同策研究院联合呈现《2020中国上市房企价值榜》，入选"2020年上市房企盈利能力榜单TOP10"。',
      '12月9日，金地集团荣获由易居克而瑞与全国房地产CIO联盟联合发布的"2020年度中国地产数字力TOP20房企"奖项。'
    ]
  },
  {
    time: '2021.05',
    pages: [
      '12月2日，第一财经携手同策研究院联合呈现《2020中国上市房企价值榜》，入选"2020年上市房企盈利能力榜单TOP10"。',
      '12月9日，金地集团荣获由易居克而瑞与全国房地产CIO联盟联合发布的"2020年度中国地产数字力TOP20房企"奖项。'
    ]
  },
];

// 发展历程-历程列表
export const COURSE_LIST = [
  {
    time: '2021.04',
    content: '加码重庆1400亩大盘拿地'
  },
  {
    time: '2021.03',
    content: '加码苏州'
  },
  {
    time: '2021.02',
    content: '加码佛山'
  },
  {
    time: '2021.02',
    content: '加码佛山'
  },
  {
    time: '2021.02',
    content: '加码佛山'
  },
  {
    time: '2021.02',
    content: '加码佛山'
  },
  {
    time: '2021.02',
    content: '加码佛山'
  },
];