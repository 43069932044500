<template>
  <div class="course-progress-item">
    <div class="time" :class="isActive ? 'active' : ''" v-if="type === 0">
      {{ time }}
    </div>
    <div class="content-cover" v-if="type === 1">
      <div class="content">{{ content }}</div>
    </div>
    <div class="course-progress">
      <point :active="isActive"></point>
      <div class="line" v-if="!isLast" :class="isActive ? 'active' : ''"></div>
    </div>
    <div class="content-cover" v-if="type === 0">
      <div class="content">{{ content }}</div>
    </div>
    <div class="time" :class="isActive ? 'active' : ''" v-if="type === 1">
      {{ time }}
    </div>
  </div>
</template>

<script>
import Point from "./Point.vue";
export default {
  name: "course-progress-item",
  props: {
    time: [String],
    content: [String],
    type: {
      type: Number,
      validator(v) {
        return [0, 1].includes(v);
      },
    }, // 0 时间在上  1 时间在下
    isActive: [Boolean],
    isLast: [Boolean]
  },
  components: {
    Point,
  },
};
</script>

<style lang="less" scoped>
.course-progress-item {
  width: 410px;
  flex-grow: 0;
  flex-shrink: 0;
  .time {
    font-size: 60px;
    font-family: YouSheBiaoTiHei;
    color: #666666;
    line-height: 78px;
    transition: 0.3s all;
    &.active {
      color: #e95506;
    }
  }
  .content-cover, .time {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .content {
    font-size: 28px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    line-height: 40px;
    width: 236px;
    text-align: center;
  }
  .course-progress {
    margin: 26px 0 51px 0;
    padding-left: 155px;
    position: relative;
    .line {
      height: 2px;
      position: absolute;
      left: 180px;
      width: 410px;
      top: 15px;
      background: #E9E9E9;
      &.active {
        background: #E95506;
      }
    }
  }
}
</style>
