<template>
  <div class="operate">
    <div class="suggest">
      <property-title
        :background-title="enPageTitle"
        :title="pageName"
        :suggest="suggest"
      ></property-title>
    </div>
    <div class="main-content">
      <div class="room-item" v-for="(room, idx) in rooms" :key="`${idx}`">
        <img :src="room.bg" alt="" class="item-bg" />
        <div class="content">
          <div class="city-name">{{ room.city }}·{{ room.name }}</div>
          <div class="en-name">{{ room.enName }}</div>
          <div class="item-suggest">{{ room.suggest }}</div>
        </div>
        <img src="../../assets/imgs/operate-4.png" alt="" class="item-modal" />
      </div>
    </div>
  </div>
</template>

<script>
import { OPERATE_SUGGEST, OPERATE_ROOM_LIST } from "./config";
import PropertyTitle from "@/components/common/PropertyTitle.vue";
export default {
  name: "operate",
  components: {
    PropertyTitle,
  },
  metaInfo: {
    title: '爱情地产',
    meta: [{
      name: 'keyWords',
      content: '爱情地产'
    },
    {
      name: 'description',
      content: ''
    }]
  },
  data() {
    return {
      pageName: "",
      enPageTitle: "",
      suggest: OPERATE_SUGGEST,
      rooms: OPERATE_ROOM_LIST,
    };
  },
  created() {
    this.pageName = this.$route.meta.name;
    this.enPageTitle = this.$route.meta.enTitle;
  },
};
</script>

<style lang="less" scoped>
.operate {
  padding-bottom: 36px;
  .main-content {
    margin-top: 44px;
    .room-item {
      width: 100%;
      position: relative;
      font-size: 0;
      overflow: hidden;
      .item-bg {
        width: 100%;
        height: 700px;
      }
      .content {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.2);
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 112px;
        transition: 0.3s all;
        .city-name {
          font-size: 60px;
          line-height: 78px;
          font-family: YouSheBiaoTiHei;
          src: url('../../assets/fonts/YouSheBiaoTiHei.ttf');
          color: #ffffff;
        }
        .en-name {
          font-size: 45px;
          line-height: 59px;
          font-family: YouSheBiaoTiHei;
          color: #ffffff;
          opacity: .5;
          letter-spacing: 3px;
        }
        .item-suggest {
          margin-top: 101px;
          font-size: 22px;
          line-height: 40px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          max-width: 1147px;
          text-align: center;
          opacity: 0;
          transition: 0.3s all;
        }
      }
      .item-modal {
        position: absolute;
        bottom: -140px;
        left: 0;
        width: 100%;
        opacity: 0;
        transition: 0.3s all;
      }
      &:hover {
        .item-modal {
          bottom: 0;
          opacity: 1;
        }
        .item-suggest {
          opacity: .7;
        }
      }
    }
  }
}
</style>
