<template>
  <div class="point circle-white">
    <div class="circle-orange" :class="active ? 'active' : ''"></div>
  </div>
</template>

<script>
export default {
  name: "point",
  props: {
    active: [Boolean],
  },
};
</script>

<style lang="less" scoped>
.circle-white {
  width: 34px;
  height: 34px;
  box-shadow: 0px 4px 14px 7px rgba(0, 0, 0, 0.05);
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: relative;
  z-index: 2;
  .circle-orange {
    border-radius: 50%;
    width: 18px;
    height: 18px;
    transition: 0.3s all;
    background: #999999;
    &.active {
      background: #e95506;
    }
  }
}
</style>
