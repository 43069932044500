<template>
  <div class="company-info-item">
    <div class="top">
      <div class="num">
        <span>{{ num }}</span>
        <div class="jia" v-if="hasIcon">+</div>
      </div>
      <div class="dw">{{ dw }}</div>
    </div>
    <div class="bottom">{{ name }}</div>
  </div>
</template>

<script>
export default {
  name: "company-info-item",
  props: {
    num: [String, Number],
    dw: [String],
    name: [String],
    hasIcon: [Boolean],
  },
};
</script>

<style lang="less" scoped>
.company-info-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  .top {
    display: flex;
    align-items: baseline;
    color: #e95506;
    .num {
      font-size: 50px;
      font-family: DINAlternate-Bold, DINAlternate;
      font-weight: bold;
      line-height: 58px;
      position: relative;
      margin-right: 10px;
      src: url("../../../assets/fonts/DIN Alternate Bold.ttf");
      .jia {
        position: absolute;
        top: 0;
        right: -14px;
        font-size: 22px;
        line-height: 30px;
      }
    }
    .dw {
      font-size: 22px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      line-height: 30px;
    }
  }
  .bottom {
    font-size: 22px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    line-height: 30px;
  }
}
</style>
