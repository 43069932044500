<template>
  <div class="dwell-item">
    <div class="img-cover">
      <img :src="'http://www.weaiove.com'+img" alt="" />
    </div>
    <div class="content">
      <div class="city-name">{{ city }} | {{ name }}</div>
      <div class="address">地址：{{ address }}</div>
      <div class="phone">电话：{{ phone }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "dwell-item",
  props: {
    img: [String],
    city: [String],
    name: [String],
    address: [String],
    phone: [String],
  },
};
</script>

<style lang="less" scoped>
.dwell-item {
  width: 508px;
  height: 500px;
  background: #ffffff;
  box-shadow: 0px 4px 14px 7px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  .img-cover {
    width: 100%;
    img {
      width: 507px;
      height: 285px;
      border-radius: 10px;
    }
  }
  .content {
    padding: 20px 30px 0 30px;
    .city-name {
      font-size: 30px;
      line-height: 42px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #000;
    }
    .address {
      margin-top: 15px;
      font-size: 20px;
      line-height: 28px;
      font-family: PingFangSC-Regular, PingFang SC;
      color: #666666;
    }
    .phone {
      margin-top: 10px;
      font-size: 20px;
      line-height: 28px;
      font-family: PingFangSC-Regular, PingFang SC;
      color: #666666;
    }
  }
}
</style>
